import React from "react";
import ReactDOM from "react-dom";

import "./index.scss";

import App from "./components/App";

ReactDOM.render(<App />, document.getElementById("root"));

// HMR
if (module.hot) {
  module.hot.accept();
}
